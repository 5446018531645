<template>
<!-- 用户个人动态主页 -->
  <div id="appContainer">
    <div
      class="cson-container"
      :style="{ height: computedHeight }"
      v-infinite-scroll="load"
    >
      <div class="left-container">
        <div class="about-company">
          <div class="title">相关公司推荐</div>
          <div
            class="companies"
            v-for="(company, index) in trendsStatistics.companies"
            :key="index"
          >
            <el-row :gutter="8">
              <el-col :span="6">
                <img :src="company.logo" class="companyLogo" alt="" />
              </el-col>
              <el-col :span="11">
                <div class="companyName">{{ company.name }}</div>
                <div class="follows">{{ company.concernNum }} Follow</div>
              </el-col>
              <el-col :span="4">
                <div
                  :class="['followBtn', company.concern ? 'grey' : '']"
                  @click="focusEvent(company.id, 1)"
                >
                  {{ company.concern ? "已关注" : "关注" }}
                </div>
              </el-col>
            </el-row>
          </div>
          <router-link to="/companyList">
            <div class="more">查看更多</div>
          </router-link>
        </div>
      </div>
      <div class="middle-container">
        <template v-if="showActivity">
          <div
            class="userInfoDescription"
            v-if="userDetail && userDetail.userName"
          >
            <div
              class="userInfoDesBanner noBanner"
              :style="{
                backgroundSize: '100% 100%',
                height: '100px',
              }"
            ></div>

            <img
              :src="userDetail && userDetail.avatar"
              class="userInfoAvatar"
              alt=""
              @click="openUpdateAvatar"
              :style="{ cursor: userId == userInfo.id ? 'pointer' : 'inherit' }"
            />
            <el-upload
              :action="uploadUrl"
              ref="upload"
              :on-success="handleUploadSuccess"
              :before-upload="beforeAvatarUpload"
              :limit="1"
              accept=".jpg, .jpeg, .png, .gif"
              :data="{ needCut: true, isPublic: true }"
              :headers="{ 'X-Access-Token': token }"
              style="display: none"
            >
              <i class="el-icon-plus" ref="uploadAvatar"></i>
            </el-upload>
            <span
              :class="['followBtn', userDetail.concern ? 'grey' : '']"
              v-if="userInfo && userInfo.id != userId"
              @click="focusEvent(userId, 0)"
              >{{ userDetail.concern ? "已关注" : "关注" }}</span
            >
            <div class="userDes">
              <div class="userName">{{ userDetail.userName }}</div>
              <div class="userIntroduction">
                <span v-if="!isEditIntroduction">
                  {{
                    userDetail.introduction && userDetail.introduction != ""
                      ? userDetail.introduction
                      : "暂无个人签名"
                  }}
                </span>
                <el-input
                  v-else
                  ref="introductionRef"
                  v-model="introduction"
                  placeholder="请输入个人签名"
                  @blur="updateUserIntroduction"
                  style="width: 400px"
                  @keydown.native.enter="$refs.introductionRef.blur()"
                ></el-input>

                <i
                  v-if="userId == userInfo.id && !isEditIntroduction"
                  class="el-icon-edit"
                  style="cursor: pointer"
                  @click="editIntroduction"
                ></i>
              </div>
              <div class="focusDetail">
                <div>
                  {{ userInfo && userInfo.id == userId ? "我" : "ta" }}关注的人
                  <span @click="jumpFocus('user')">{{ userDetail.user }}</span>
                </div>
                <div>
                  {{
                    userInfo && userInfo.id == userId ? "我" : "ta"
                  }}关注的公司
                  <span @click="jumpFocus('company')">{{
                    userDetail.company
                  }}</span>
                </div>
                <div>
                  {{ userInfo && userInfo.id == userId ? "我" : "ta" }}的粉丝
                  <span @click="jumpFocus('fans')">{{ userDetail.fans }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="trends">
            <div class="publishArea">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane
                  :label="
                    userInfo && userId == userInfo.id ? '我的动态' : 'ta的动态'
                  "
                  name="trends"
                >
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    userInfo && userId == userInfo.id ? '我的面经' : 'ta的面经'
                  "
                  name="interviewExps"
                >
                </el-tab-pane>
                <el-tab-pane
                  :label="
                    userInfo && userId == userInfo.id
                      ? '我的面经题'
                      : 'ta的面经题'
                  "
                  name="questions"
                >
                </el-tab-pane>
              </el-tabs>
            </div>
            <div class="trendsList">
              <div
                class="trendsComment"
                v-for="(trend, index) in trendsList"
                :key="index"
                @click="viewTrends(trend)"
              >
                <!-- 动态/最新消息/求职感受部分 -->
                <div
                  v-if="
                    trend.type.value == 1 ||
                    trend.type.value == 5 ||
                    trend.type.value == 6
                  "
                >
                  <el-row :gutter="16">
                    <el-col :span="2" style="text-align: right">
                      <img
                        v-if="trend.avatar"
                        :src="trend.avatar"
                        class="userAvatar"
                        alt=""
                      />
                      <div v-else class="anonymous">匿名</div>
                    </el-col>
                    <el-col :span="22">
                      <div class="username">
                        {{ trend.username ? trend.username : "匿名用户" }}
                        <span class="createTime">{{ trend.createTime }}</span>
                        <span
                          class="trendType orange"
                          v-if="trend.content.companyName"
                          >{{ trend.content.companyName }}</span
                        >
                      </div>
                      <div class="trends-content">
                        {{ trend.content.content }}
                      </div>
                      <div class="trends-pictures">
                        <el-image
                          v-for="(image, index) in trend.content.pictures"
                          style="
                            width: 100px;
                            height: 100px;
                            margin-right: 12px;
                          "
                          :src="image"
                          :key="index"
                          @click="
                            picArray = trend.content.pictures;
                            showIndex = index;
                            showViewer = true;
                          "
                        />
                      </div>
                      <div class="trends-operator">
                        <div
                          class="likeNums"
                          @click="publishChildComment(trend, false)"
                        >
                          <i class="iconfont icon-dianzan"></i
                          >{{ trend.likeNums }}
                        </div>
                        <div
                          class="commentNums"
                          @click="openParentReply(trend)"
                        >
                          回复
                        </div>
                      </div>
                      <div class="replyContainer">
                        <el-input
                          v-if="trend.reply"
                          v-model="trend.replyText"
                          @input="$forceUpdate()"
                          :placeholder="`评论${
                            trend.username ? trend.username : '匿名用户'
                          }...`"
                        >
                          <template slot="append">
                            <emotions
                              @chooseEmotion="
                                (emotion) =>
                                  handleChildCommentEmotion(emotion, trend)
                              "
                            ></emotions>
                            <el-button
                              style="
                                margin-left: 4px;
                                border-left: 1px solid #dcdfe6;
                                border-radius: 0px;
                              "
                              @click="publishChildComment(trend, true)"
                              >评论</el-button
                            >
                          </template>
                        </el-input>

                        <div class="replyList" v-if="trend.content.children">
                          <div
                            class="replies"
                            v-for="(reply, rIndex) in trend.content.showAll ||
                            trend.content.total <= 2
                              ? trend.content.children
                              : trend.content.children.slice(0, 2)"
                            :key="rIndex"
                          >
                            <img :src="reply.avatar" class="replyUserAvatar" />
                            <div
                              v-if="!reply.toAvatar || !reply.toUsername"
                              class="reply-area"
                            >
                              {{ reply.username }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div v-else class="reply-area">
                              {{ reply.username }}
                              <i class="el-icon-caret-right"></i>
                              {{ reply.toUsername }}:
                              <span class="commentContent">
                                {{
                                  reply.visibility
                                    ? reply.content
                                    : "该评论已删除"
                                }}
                              </span>
                            </div>
                            <div class="reply-operator">
                              <span class="reply-time">{{
                                reply.createTime
                              }}</span>
                              <span class="reply-likeNum">
                                <i
                                  class="iconfont icon-dianzan"
                                  @click="
                                    publishSecondChildComment(
                                      trend,
                                      reply,
                                      false
                                    )
                                  "
                                ></i>
                                {{ reply.likeNums }}</span
                              >
                              <span
                                class="reply-reply"
                                @click="openChildReply(reply)"
                                >回复</span
                              >
                            </div>
                            <div
                              class="replys"
                              v-if="reply.reply"
                              style="margin-top: 8px"
                            >
                              <el-input
                                v-model="reply.replyText"
                                :placeholder="`回复${reply.username}...`"
                                @input="$forceUpdate()"
                              >
                                <template slot="append">
                                  <emotions
                                    @chooseEmotion="
                                      (emotion) =>
                                        handleChildCommentEmotion(
                                          emotion,
                                          reply
                                        )
                                    "
                                  ></emotions>
                                  <el-button
                                    style="
                                      margin-left: 4px;
                                      border-left: 1px solid #dcdfe6;
                                      border-radius: 0px;
                                    "
                                    @click="
                                      publishSecondChildComment(
                                        trend,
                                        reply,
                                        true
                                      )
                                    "
                                    >回复</el-button
                                  >
                                </template>
                              </el-input>
                            </div>
                          </div>
                          <div
                            style="text-align: left; padding: 12px 0"
                            v-if="
                              trend.content.total > 10 && trend.content.showAll
                            "
                          >
                            <el-pagination
                              style="padding: 0"
                              prev-text="上一页"
                              next-text="下一页"
                              :current-page.sync="trend.content.current"
                              :total="trend.content.total"
                              layout="prev, pager, next"
                              @current-change="
                                (page) => getTrendsCommentList(trend, page)
                              "
                            >
                            </el-pagination>
                          </div>
                          <div
                            v-if="
                              trend.content.total > 2 && !trend.content.showAll
                            "
                          >
                            共{{ trend.content.total }}条回复，<span
                              style="color: #00a1d6; cursor: pointer"
                              @click="
                                trend.content.showAll = true;
                                $forceUpdate();
                              "
                              >点击查看</span
                            >
                          </div>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!-- 面经部分 -->
                <div v-else-if="trend.type.value == 2">
                  <a :href="trend.href" target="_blank">
                    <el-row :gutter="16">
                      <el-col :span="2" style="text-align: right">
                        <img
                          v-if="trend.avatar"
                          :src="trend.avatar"
                          class="userAvatar"
                          alt=""
                        />
                        <div v-else class="anonymous">匿名</div>
                      </el-col>
                      <el-col :span="22">
                        <div class="username">
                          {{ trend.username ? trend.username : "匿名用户" }}
                          <span class="createTime">{{ trend.createTime }}</span>
                          <span class="trendType">{{ trend.type.name }}</span>
                        </div>
                        <div class="trendLabels">
                          <span
                            v-for="(label, lIndex) in trend.content.labels"
                            :key="lIndex"
                            :class="[
                              lIndex == 0
                                ? 'orange'
                                : lIndex == 1
                                ? 'red'
                                : lIndex == 2
                                ? 'green'
                                : lIndex == 3
                                ? 'blue'
                                : lIndex == 4
                                ? 'sgreen'
                                : '',
                            ]"
                          >
                            {{ label }}
                            <span
                              style="padding: 0 5px; color: #828282 !important"
                              >{{
                                lIndex == trend.content.labels.length - 1
                                  ? ""
                                  : "|"
                              }}</span
                            ></span
                          >
                        </div>
                        <div class="trendTitle">
                          <div class="title-text">
                            {{ trend.content.title }}
                          </div>
                          <div class="questionNum">
                            {{ trend.content.questions }}题
                          </div>
                        </div>
                        <div class="trends-content ellipsis">
                          {{ trend.content.interviewAfter }}
                        </div>
                        <div class="trends-pictures">
                          <el-image
                            v-for="(image, index) in trend.content.pictures"
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 12px;
                            "
                            :src="image"
                            :key="index"
                            @click="
                              picArray = trend.content.pictures;
                              showIndex = index;
                              showViewer = true;
                            "
                          />
                        </div>
                        <div class="trends-operator">
                          <div class="likeNums">
                            <i class="iconfont icon-dianzan"></i
                            >{{ trend.likeNums }}
                          </div>
                          <div class="commentNums">
                            <i class="iconfont icon-huifu"></i
                            >{{ trend.commentNums }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </a>
                </div>
                <!-- 面经题部分 -->
                <div v-else-if="trend.type.value == 3">
                  <a :href="trend.href" target="_blank">
                    <el-row :gutter="16">
                      <el-col :span="2" style="text-align: right">
                        <img
                          v-if="trend.avatar"
                          :src="trend.avatar"
                          class="userAvatar"
                          alt=""
                        />
                        <div v-else class="anonymous">匿名</div>
                      </el-col>
                      <el-col :span="22">
                        <div class="username">
                          {{ trend.username ? trend.username : "匿名用户" }}
                          <span class="createTime">{{ trend.createTime }}</span>
                          <span class="trendType">{{ trend.type.name }}</span>
                        </div>
                        <div style="margin-top: 8px">
                          <div class="companyName">
                            {{ trend.content.companyName }}
                          </div>
                          <div
                            :class="[
                              'isExplain',
                              trend.content.isExplain ? 'active' : '',
                            ]"
                          >
                            {{ trend.content.isExplain ? "已解答" : "待解答" }}
                          </div>
                        </div>
                        <div class="question-title">
                          {{ trend.content.interviewQuestions }}
                        </div>
                        <div class="trends-operator">
                          <div class="likeNums">
                            <i class="iconfont icon-dianzan"></i
                            >{{ trend.likeNums }}
                          </div>
                          <div class="commentNums">
                            <i class="iconfont icon-huifu"></i
                            >{{ trend.commentNums }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </a>
                </div>
                <!-- 经感部分 -->
                <div v-else-if="trend.type.value == 4">
                  <a :href="trend.href" target="_blank">
                    <el-row :gutter="16">
                      <el-col :span="2" style="text-align: right">
                        <img
                          v-if="trend.avatar"
                          :src="trend.avatar"
                          class="userAvatar"
                          alt=""
                        />
                        <div v-else class="anonymous">匿名</div>
                      </el-col>
                      <el-col :span="22">
                        <div class="username">
                          {{ trend.username ? trend.username : "匿名用户" }}
                          <span class="createTime">{{ trend.createTime }}</span>
                          <span class="trendType">{{ trend.type.name }}</span>
                        </div>
                        <div class="trendTitle">
                          <div class="title-text">
                            {{ trend.content.title }}
                          </div>
                        </div>
                        <div
                          class="trends-content renderContent"
                          v-html="trend.content.content"
                        ></div>
                        <div class="trends-pictures">
                          <el-image
                            v-for="(image, index) in trend.content.pictures"
                            style="
                              width: 100px;
                              height: 100px;
                              margin-right: 12px;
                            "
                            :src="image"
                            :key="index"
                            @click="
                              picArray = trend.content.pictures;
                              showIndex = index;
                              showViewer = true;
                            "
                          />
                        </div>
                        <div class="trends-operator">
                          <div class="likeNums">
                            <i class="iconfont icon-dianzan"></i
                            >{{ trend.likeNums }}
                          </div>
                          <div class="commentNums">
                            <i class="iconfont icon-huifu"></i
                            >{{ trend.commentNums }}
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </a>
                </div>
              </div>
              <div class="no-trends" v-if="trendsList.length == 0">
                暂无动态！
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="focusList">
            <div class="return-title">
              <i class="el-icon-arrow-left" @click="showActivity = true"></i>
              {{ userInfo && userInfo.id == userId ? "我" : "ta" }}的主页
            </div>

            <el-tabs
              v-model="activeFocus"
              class="focusContainer"
              @tab-click="handleFocusClick"
            >
              <el-tab-pane
                :label="
                  userInfo && userId == userInfo.id
                    ? `我关注的人(${userDetail.user})`
                    : `ta关注的人(${userDetail.user})`
                "
                name="user"
              ></el-tab-pane>
              <el-tab-pane
                :label="
                  userInfo && userId == userInfo.id
                    ? `我关注的公司(${userDetail.company})`
                    : `ta关注的公司(${userDetail.company})`
                "
                name="company"
              ></el-tab-pane>
              <el-tab-pane
                :label="
                  userInfo && userId == userInfo.id
                    ? `我的粉丝(${userDetail.fans})`
                    : `ta的粉丝(${userDetail.fans})`
                "
                name="fans"
              ></el-tab-pane>
            </el-tabs>
            <div class="focusContainerList">
              <div
                v-for="(focus, index) in focusList"
                :key="index"
                class="focus"
              >
                <template v-if="activeFocus == 'user' || activeFocus == 'fans'">
                  <div class="person">
                    <el-row>
                      <el-col :span="4">
                        <img :src="focus.avatar" alt="" class="companyLogo" />
                      </el-col>
                      <el-col :span="14" style="padding-left: 12px">
                        <div class="focusName">
                          {{ focus.name }}
                        </div>
                        <div class="focusDes">
                          {{
                            focus.introduction
                              ? focus.introduction
                              : "暂无个人签名"
                          }}
                        </div>
                      </el-col>
                      <el-col
                        v-if="activeFocus == 'user' && userId == userInfo.id"
                        :span="6"
                      >
                        <div
                          :class="[
                            'followBtn',
                            !focus.isNotFocus ? 'grey' : '',
                          ]"
                          @click="focusListEvent(focus, focus.id, 0)"
                        >
                          {{ !focus.isNotFocus ? "取消关注" : "关注" }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
                <template v-else-if="activeFocus == 'company'">
                  <div class="company">
                    <el-row>
                      <el-col :span="4">
                        <img :src="focus.avatar" alt="" class="companyLogo" />
                      </el-col>
                      <el-col :span="14" style="padding-left: 12px">
                        <div class="focusName">
                          {{ focus.name }}
                        </div>
                        <div class="focusDes">{{ focus.remark }}</div>
                      </el-col>
                      <el-col :span="6" v-if="userId == userInfo.id">
                        <div
                          :class="[
                            'followBtn',
                            !focus.isNotFocus ? 'grey' : '',
                          ]"
                          @click="focusListEvent(focus, focus.id, 1)"
                        >
                          {{ !focus.isNotFocus ? "取消关注" : "关注" }}
                        </div>
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </div>
              <div v-if="focusList.length == 0" class="noFocus">暂无关注</div>
            </div>
          </div>
        </template>
      </div>
      <div class="right-container">
        <div class="interviewExpression">
          <div class="title">经验感悟</div>
          <div
            class="interviewExp"
            v-for="(interviewExp, index) in trendsStatistics.experiences"
            :key="index"
          >
            <router-link :to="`/interviewExpDetail?id=${interviewExp.id}`">
              <div class="interviewTitle">{{ interviewExp.title }}</div>
              <div
                class="interviewDes renderContent"
                v-html="interviewExp.content"
              ></div>
            </router-link>
          </div>
          <router-link to="/interviewExpression">
            <div class="more">查看更多</div></router-link
          >
        </div>
      </div>
      <el-image-viewer
        v-if="showViewer"
        :initial-index="showIndex"
        :url-list="picArray"
        :on-close="closeViewer"
      />
    </div>
  </div>
</template>
<script>
import emotions from "@/utils/emotions/index";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapState } from "vuex";
import {
  getTrendsStatistics,
  focusObject,
  browseTrends,
} from "@/service/trends";
import { getTrendsList, getFocusList } from "@/service/user";
import {
  getTrendsComments,
  publishComment,
  getTrendDetail,
} from "@/service/comments";
import { getUserDetail } from "@/service/user";
import { updateUserInfo } from "@/service/index";
import _ from "lodash";
export default {
  name: "userPage",
  components: { ElImageViewer, emotions },
  data() {
    return {
      activeTab: "trends",
      activeName: "trends",
      trendsDropText: "全部",
      trendsStatistics: {
        experiences: [],
        companies: [],
      },
      currentPage: 1,
      total: 0,
      trendsList: [],
      picArray: [],
      showIndex: 0,
      showViewer: false,
      findJobList: [],
      filters: [
        {
          name: "全部",
          status: "",
        },
        {
          name: "最新信息",
          status: "1",
        },
        {
          name: "求职感受",
          status: "0",
        },
        {
          name: "New Grad",
          status: "3",
        },
        {
          name: "Internship",
          status: "2",
        },
        {
          name: "在职跳槽",
          status: "4",
        },
      ],
      activeFilter: 0,
      searchCompanyKey: "",
      companys: [],
      titleText: "全部",
      jobType: "",
      companyId: "",
      userId: "",
      showActivity: true,
      activeFocus: "user",
      focusList: [{}],
      userDetail: {},
      trendType: '',
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      focusCurrentPage: 1,
      focusTotal: 0,
      isEditIntroduction: false,
      introduction: "",
    };
  },
  computed: {
    ...mapState(["userInfo"]),
    computedHeight() {
      return window.innerHeight - 70 + "px";
    },
  },
  methods: {
    handleClick(tab) {
      if (tab.name == "trends") {
        this.trendType = "";
      } else if (tab.name == "interviewExps") {
        this.trendType = 2;
      } else if (tab.name == "questions") {
        this.trendType = 3;
      }
      this.getTrendsList();
    },
    handleFocusClick(tab) {
      if (tab.name == "user") {
        this.getFocusList(0);
      } else if (tab.name == "company") {
        this.getFocusList(1);
      } else if (tab.name == "fans") {
        this.getFocusList(2);
      }
    },
    getTrendsStatistics() {
      getTrendsStatistics().then((res) => {
        if (res.success) {
          this.trendsStatistics = res.result;
          this.replaceImgToText();
        }
      });
    },
    focusEvent(id, type) {
      focusObject({
        superId: id,
        type: type,
      }).then((res) => {
        if (res.success) {
          this.getUserDetail();
          this.getTrendsStatistics();
          this.$message.closeAll();
          this.$message.success("操作成功!");
        }
      });
    },
    focusListEvent(item, id, type) {
      item.isNotFocus = !item.isNotFocus;
      focusObject({
        superId: id,
        type: type,
      }).then((res) => {
        if (res.success) {
          this.getUserDetail();
          this.$message.closeAll();
          this.$message.success("操作成功!");
        }
      });
    },
    getTrendsList() {
      this.trendsList = [];
      this.currentPage = 1;
      getTrendsList({
        current: 1,
        userId: this.$route.query.userId,
        type: this.trendType,
      }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.trendsList = res.result.records;
          this.replaceImgToText();
        }
      });
    },
    getFocusList(type) {
      this.focusList = [];
      this.focusCurrentPage = 1;
      getFocusList(
        {
          current: 1,
          type: type,
          size: 20,
        },
        this.$route.query.userId
      ).then((res) => {
        if (res.success) {
          this.focusTotal = res.result.total;
          this.focusList = res.result.records;
        }
      });
    },
    loadTrends() {
      if (this.trendsList.length >= this.total) {
        return;
      }
      this.currentPage = this.currentPage + 1;
      getTrendsList({
        current: this.currentPage,
        userId: this.$route.query.userId,
        type: this.trendType,
      }).then((res) => {
        if (res.success) {
          this.total = res.result.total;
          this.trendsList = this.trendsList.concat(res.result.records);
          this.replaceImgToText();
        }
      });
    },
    loadFocus() {
      if (this.focusList.length >= this.focusTotal) {
        return;
      }
      this.focusCurrentPage = this.focusCurrentPage + 1;

      let type;
      if (this.activeFocus == "user") {
        type = 0;
      } else if (this.activeFocus == "company") {
        type = 1;
      } else if (this.activeFocus == "fans") {
        type = 2;
      }
      getFocusList(
        {
          current: this.focusCurrentPage,
          type: type,
          size: 20,
        },
        this.$route.query.userId
      ).then((res) => {
        if (res.success) {
          this.focusTotal = res.result.total;
          this.focusList = this.focusList.concat(res.result.records);
        }
      });
    },
    load() {
      if (this.showActivity) {
        this.loadTrends();
      } else {
        this.loadFocus();
      }
    },
    closeViewer() {
      this.showViewer = false;
      this.picArray = [];
    },
    replaceImgToText() {
      this.$nextTick(() => {
        const renderArray = document.querySelectorAll(".renderContent");
        for (let i = 0; i < renderArray.length; i++) {
          let picArray = renderArray[i].querySelectorAll("img");
          for (let j = 0; j < picArray.length; j++) {
            let newNode = document.createTextNode("[图片]");
            console.log(picArray[j]);
            picArray[j].parentNode.replaceChild(newNode, picArray[j]);
          }
        }
      });
    },
    jumpInterviewExpression(id) {
      this.$router.push(`/interviewExpDetail?id=${id}`);
    },
    openParentReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    openChildReply(item) {
      item.reply = item.reply ? !item.reply : true;
      this.$forceUpdate();
    },
    handleChildCommentEmotion(emotion, comment) {
      if (comment.replyText) {
        comment.replyText = comment.replyText + emotion;
      } else {
        comment.replyText = "" + emotion;
      }
      this.$forceUpdate();
    },
    publishChildComment(comment, isComment) {
      let commentType;
      switch (comment.type.value) {
        case 1:
          commentType = 0;
          break;
        case 5:
          commentType = 8;
          break;
        case 6:
          commentType = 9;
          break;
      }
      if (isComment && (!comment.replyText || comment.replyText == "")) {
        return this.$message.error("请输入评论内容！");
      }
      console.log(comment);
      const data = {
        content: isComment ? comment.replyText : "",
        superType: commentType,
        type: isComment,
        superId: comment.content.id,
        parentId: 0,
        commentId: 0,
      };
      publishComment(data).then((res) => {
        if (res.success) {
          this.$message.success(`${isComment ? "评论" : "点赞"}成功！`);
          this.updateTrend(comment);
          this.updateTrendDetail(comment);
          comment.replyText = "";
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    publishSecondChildComment(parentComment, childComment, isComment) {
      let parentCommentType;
      switch (parentComment.type.value) {
        case 1:
          parentCommentType = 0;
          break;
        case 5:
          parentCommentType = 8;
          break;
        case 6:
          parentCommentType = 9;
          break;
      }
      if (
        isComment &&
        (!childComment.replyText || childComment.replyText == "")
      ) {
        return this.$message.error("请输入内容！");
      }
      const data = {
        content: isComment ? childComment.replyText : "",
        superType: parentCommentType,
        type: isComment,
        superId: parentComment.content.id,
        parentId: childComment.id,
        commentId: childComment.id,
      };
      publishComment(data).then(async (res) => {
        if (res.success) {
          this.$message.success("评论成功！");
          this.updateTrend(parentComment);
          this.updateTrendDetail(parentComment);
          this.$forceUpdate();
        } else {
          this.$message.info(res.message);
        }
      });
    },
    updateTrend(trend) {
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      trend.content.current = trend.content.current ? trend.content.current : 1;
      getTrendsComments(trend.content.id, trend.content.current, type).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            trend.content.showAll = true;
            this.$forceUpdate();
          }
        }
      );
    },

    updateTrendDetail(trend) {
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      getTrendDetail(trend.content.id, type).then((res) => {
        if (res.success) {
          console.log(res);
          trend.likeNums = res.result.likeNums;
          trend.commentNums = res.result.commentNums;
        }
      });
    },
    getTrendsCommentList(trend, page) {
      trend.content.current = page;
      let type;
      switch (trend.type.value) {
        case 1:
          type = 0;
          break;
        case 5:
          type = 8;
          break;
        case 6:
          type = 9;
          break;
      }
      getTrendsComments(trend.content.id, trend.content.current, type).then(
        (res) => {
          if (res.success) {
            trend.content.children = res.result.records;
            trend.content.total = res.result.total;
            this.$forceUpdate();
          }
        }
      );
    },

    viewTrends(trend) {
      if (
        trend.type.value == 2 ||
        trend.type.value == 3 ||
        trend.type.value == 4
      ) {
        console.log(trend.id);
        browseTrends(trend.id);
      }
    },
    getUserDetail() {
      getUserDetail(this.$route.query.userId).then((res) => {
        if (res.success) {
          this.userDetail = res.result;
        }
      });
    },
    jumpFocus(type) {
      this.activeFocus = type;
      this.showActivity = false;
      if (type == "user") {
        this.getFocusList(0);
      } else if (type == "company") {
        this.getFocusList(1);
      } else if (type == "fans") {
        this.getFocusList(2);
      }
    },
    openUpdateAvatar() {
      if (this.userId != this.userInfo.id) {
        return;
      }
      this.$refs.uploadAvatar.click();
    },
    handleUploadSuccess(res) {
      console.log(res);
      updateUserInfo({ avatar: res.result.objectKey }).then((updateRes) => {
        if (updateRes.success) {
          this.$message.success("上传头像成功!");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        }
      });
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    editIntroduction() {
      this.isEditIntroduction = true;
      this.introduction = this.userDetail.introduction
        ? this.userDetail.introduction
        : "";
    },
    updateUserIntroduction() {
      updateUserInfo({ introduction: this.introduction }).then((updateRes) => {
        if (updateRes.success) {
          this.$message.success("更新个人签名成功!");
          this.isEditIntroduction = false;
          this.getUserDetail();
        }
      });
    },
  },
  mounted() {
    this.userId = this.$route.query.userId;
    this.getTrendsList();
    this.getTrendsStatistics();
    this.getUserDetail();
  },
};
</script>
<style scoped lang="scss">
#appContainer {
  min-height: 800px;
  background: #f8f8f8;
  padding-top: 16px;
}
.cson-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  overflow-y: auto;
  justify-content: center;
}
.middle-container {
  width: 800px;
  margin: 0 10px;
  background: #fff;
}
.left-container {
  width: 230px;
  .userInfo,
  .about-company {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
  }
  .userInfo {
    text-align: center;
    .userAvatar {
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
    }
    .userName {
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 21px;
      margin-top: 8px;
      margin-bottom: 6px;
    }
    .userSign {
      margin: 0 auto;
      width: 100%;
      padding: 0 5%;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(52, 73, 94, 0.6);
      line-height: 14px;
      padding-bottom: 14px;
      border-bottom: 1px solid #e4e4e4;
    }
    .focus {
      margin-top: 16px;
      text-align: left;
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 12px;
      .focus-person {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
      }
      .focus-company {
        display: flex;
        justify-content: space-between;
      }
      .count {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #0075f6;
        line-height: 12px;
      }
    }
  }
  .about-company {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    .title {
      position: relative;
      font-weight: 700;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .more {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #0075f6;
      line-height: 18px;
      display: inline-block;
    }
  }
}
.middle-container {
  .button-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
    .activeTab {
      width: 49.5%;
      height: 42px;
      line-height: 42px;
      box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      cursor: pointer;
      &.active {
        border: 1px solid #0075f6;
        color: #0075f6;
      }
    }
  }
  .publishArea {
    padding: 8px 40px;
    box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
    background: #fff;
  }
}

.right-container {
  width: 250px;
  .interviewExpression {
    width: 100%;
    padding: 26px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    .title {
      position: relative;
      font-weight: 700;
      color: #34495e;
      font-size: 16px;
      padding-left: 14px;
      line-height: 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 12px;
      margin-bottom: 12px;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 0;
      }
    }
    .more {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #0075f6;
      line-height: 18px;
      display: inline-block;
    }
    .interviewExp {
      cursor: pointer;
      margin-bottom: 16px;
      .interviewTitle {
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 18px;
      }
      .interviewDes {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #828282;
        line-height: 18px;
      }
    }
  }
}

::v-deep .el-divider__text {
  background-color: #f8f8f8 !important;
}
.companies {
  .companyLogo {
    width: 36px;
    height: 36px;
  }
  .companyName,
  .follows {
    line-height: 18px;
  }
  .companyName {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .follows {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 18px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .followBtn {
    width: 53px;
    height: 20px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 11px;
    border: 1px solid #0075f6;
    line-height: 20px;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;
    &.grey {
      background: rgba(52, 73, 94, 0.2);
      color: rgba(52, 73, 94, 1);
      border: 1px solid rgba(120, 120, 128, 0.36);
    }
  }
  &:not(:last-child) {
    margin-bottom: 12px;
  }
}
.trendsList {
  padding: 20px 30px;
  padding-top: 0px;
  background: #fff;
}
.trends {
  overflow-y: auto;
  .trendsComment {
    padding: 20px 0;
  }
  .trendsComment:not(:last-child) {
    border-bottom: 1px solid #e4e4e4;
  }
  .userAvatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
  .anonymous {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #ffffff;
    line-height: 36px;
    background: rgba(10, 122, 255, 1);
    text-align: center;
  }
  .username {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 21px;
  }
  .reply {
    float: right;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    cursor: pointer;
  }
  .replyContainer {
    margin-top: 8px;
    .replyList {
      margin-top: 8px;
      .replies {
        padding: 8px 0;
        .replyUserAvatar {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 6px;
        }
        .reply-area {
          display: inline-block;
          width: calc(100% - 40px);
          vertical-align: top;
          line-height: 24px;

          .commentContent {
            color: #222;
            word-break: break-all;
          }
        }
        .reply-operator {
          .reply-time {
            color: #34495e;
            margin-right: 24px;
          }
          .reply-likeNum {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
            margin-right: 24px;
          }
          .reply-reply {
            color: rgba(52, 73, 94, 0.7);
            cursor: pointer;
          }
        }
      }
    }
  }
  .createTime {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: rgba(52, 73, 94, 0.5);
    line-height: 21px;
    margin-left: 12px;
  }
  .trendType {
    width: 50px;
    padding: 2px 12px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 9px;
    line-height: 16px;
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    margin-left: 18px;
    &.orange {
      color: #fa6400 !important;
      background: rgba(250, 100, 0, 0.2);
    }
  }
  .orange {
    color: #fa6400 !important;
  }

  .blue {
    color: #2772db !important;
  }

  .green {
    color: #2ec1ac !important;
  }

  .red {
    color: #e02020 !important;
  }
  .sgreen {
    color: #2fbb1e !important;
  }
  .trendLabels {
    font-size: 12px;
    margin-top: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trendTitle {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
    .title-text {
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
      max-width: calc(100% - 60px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .questionNum {
      background: rgba(10, 122, 255, 0.1);
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #0a7aff;
      line-height: 18px;
      padding: 2px 6px;
      margin-left: 8px;
    }
  }
  .companyName {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #fa6400;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
  }
  .isExplain {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #34c759;
    line-height: 18px;
    background: rgba(52, 199, 89, 0.1);
    padding: 3px 6px;
    display: inline-block;
    &.active {
      color: #e02020;
      background: rgba(224, 32, 32, 0.1);
    }
  }
  .question-title {
    margin-top: 8px;
    background: rgba(136, 148, 171, 0.1);
    border-radius: 2px;
    padding: 6px 10px;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #292b2d;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .trends-content {
    font-size: 12px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #828282;
    line-height: 20px;
    margin-top: 8px;
    &.ellipsis {
      display: -webkit-box;
      -webkit-line-clamp: 2; /*让他显示两行*/
      -webkit-box-orient: vertical;
      word-break: break-all; /* 允许在单词内换行。*/
      overflow: hidden;
    }
  }
  .trends-pictures {
    margin-top: 9px;
  }
  .trends-operator {
    margin-top: 12px;
    color: #34495e !important;
    .iconfont {
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #828282;
      line-height: 16px;
    }
    .likeNums {
      display: inline-block;
      margin-right: 20px;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 2px;
      }
    }
    .commentNums {
      display: inline-block;
      color: #828282 !important;
      cursor: pointer;
      i {
        margin-right: 4px;
      }
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  /*正常情况下滑块的样式*/
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在该类指向的控件上时滑块的样式*/
  &:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*鼠标悬浮在滑块上时滑块的样式*/
  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: inset1px1px0rgba(0, 0, 0, 0.1);
  }
  /*正常时候的主干部分*/
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    box-shadow: inset006pxrgba(0, 0, 0, 0);
    background-color: white;
  }
  /*鼠标悬浮在滚动条上的主干部分*/
  &::-webkit-scrollbar-track:hover {
    box-shadow: inset006pxrgba(0, 0, 0, 0.4);
    background-color: rgba(0, 0, 0, 0.01);
  }
  .no-trends {
    text-align: center;
    font-size: 20px;
    padding: 20px;
  }
}
.findJobsList {
  background: #fff;
  margin-top: 10px;
  border-radius: 4px;
  box-shadow: 0px 0px 5px 0px rgba(136, 148, 171, 0.2);
  .findJobHeader {
    height: 60px;
    line-height: 60px;
    display: flex;
    .title {
      width: 63%;
      margin-left: 36px;
      position: relative;
      padding-left: 8px;
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      &::before {
        position: absolute;
        content: "";
        width: 3px;
        height: 20px;
        background: #0a7aff;
        border-radius: 2px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .searchCompany {
      width: 30%;
    }
  }
  .filters {
    background: #f8f8f8;
    display: flex;
    justify-content: flex-start;
    padding: 12px 24px;
    .filter {
      padding: 2px 9px;
      margin: 0 9px;
      font-size: 14px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #999999;
      cursor: pointer;
      line-height: 14px;
      &.active {
        background: rgba(0, 117, 246, 0.2);
        border-radius: 10px;
        color: #0075f6;
      }
    }
  }
}
.renderContent {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.userInfoDescription {
  width: 100%;
  background: #fff;
  .userInfoDesBanner.noBanner {
    background-image: url(../../assets/img/userInfoBanner.png);
    background-repeat: no-repeat;
  }
  .followBtn {
    float: right;
    margin-right: 64px;
    margin-top: 8px;
    padding: 4px 16px;
    background: rgba(0, 117, 246, 0.2);
    border-radius: 16px;
    border: 1px solid #0075f6;
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    cursor: pointer;
    &.grey {
      background: rgba(52, 73, 94, 0.2);
      color: rgba(52, 73, 94, 1);
      border: 1px solid rgba(120, 120, 128, 0.36);
    }
  }
  img {
    width: 100%;
    vertical-align: top;
  }
  .userInfoAvatar {
    width: 108px;
    height: 108px;
    border-radius: 50%;
    border: 2px solid #fff;
    margin-left: 40px;
    margin-top: -54px;
  }
  .userDes {
    margin-top: 8px;
    padding: 0 40px;
    .userName {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 24px;
    }
    .userIntroduction {
      margin-top: 8px;
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(52, 73, 94, 0.6);
      line-height: 12px;
    }
    .focusDetail {
      display: flex;
      justify-content: flex-start;
      padding: 20px 0;
      & > div {
        margin-right: 40px;
        font-size: 12px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        line-height: 12px;
        span {
          color: rgba(0, 117, 246, 1);
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
.focusList {
  padding: 26px;
  background: #fff;
  .return-title {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #34495e;
    line-height: 24px;
    i {
      font-size: 24px;
      vertical-align: top;
      cursor: pointer;
    }
  }
  .focusContainer {
    margin-top: 36px;
  }
  .focusContainerList {
    padding: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .focus {
      width: 48%;
      padding: 18px;
      border: 1px solid rgba(120, 120, 128, 0.2);
      background: #ffffff;
      border-radius: 4px;

      margin-bottom: 12px;
    }
    .person,
    .company {
      .companyLogo {
        width: 100%;
      }
      .focusName {
        font-size: 14px;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-weight: 500;
        color: #34495e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .focusDes {
        font-size: 12px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #828282;
        margin-top: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .followBtn {
      padding: 4px 8px;
      background: rgba(0, 117, 246, 0.2);
      border-radius: 14px;
      border: 1px solid #0075f6;
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #0075f6;
      text-align: center;
      cursor: pointer;
      margin-top: 6px;
      &.grey {
        background: rgba(118, 118, 128, 0.12);
        color: #999;
        border: 1px solid rgba(120, 120, 128, 0.36);
      }
    }
  }
}
.noFocus {
  text-align: center;
  padding: 20px;
  font-size: 20px;
  margin: 0 auto;
}
</style>